<template>
  <landing v-if="!this.isLoggedIn && this.landingpage" />
  <div
      v-else
      class="h-auto max-w-[100%] md:min-h-[auto] flex flex-col p-0 md:p-4 items-center child:max-w-[1200px]">
    <div class="grid grid-cols-1 py-4 w-full max-[480px]:p-0">
      <home-slider />
      <bet-wins />
      <auth v-if="false" />
      <home-category-slider
          v-for="(cat, i) in getHomeCategories"
          :key="i"
          :category="cat"
      />
      <!-- <RelaxHomeTiles
	:categories="trendingOptions.categories"
	:games="trendingGames"
	:icon="trendingOptions.icon"
	:slug="trendingOptions.slug"
	:title="trendingOptions.title" />-->
      <QuickBuy />
      <GameHistoryTable />

      <!-- Conversion Pixel for [lead]- DO NOT MODIFY -->
        <img src="https://arttrk.com/pixel/?ad_log=referer&action=lead&pixid=3efef876-d9d4-4bea-919b-ffe126c18916" width="1" height="1" border="0">
      <!-- End of Conversion Pixel -->
    </div>
  </div>
</template>

<script>
import Auth from '@/components/Home/Auth.vue';
import Landing from '@/components/Home/Landing.vue';
import BetWins from '@/components/Home/BetWins.vue';
import QuickBuy from '@/components/Home/QuickBuy.vue';
import GameHistoryTable from '../GameHistory/BetsHistory.vue';

import { mapGetters } from 'vuex';
import HomeSlider from '@/components/Home/Slider/HomeSlider.vue';
import HomeCategorySlider from "@/components/Home/HomeCategorySlider.vue";
import { icons } from "@/components/Shared/icons.js";
import { relaxStore } from "@/store/pinia/relaxStore.js";
import RelaxHomeTiles from '@/components/Relax/RelaxHomeTiles.vue';

export default {
  name: 'Home',
  components: {
    HomeCategorySlider,
    HomeSlider,
    QuickBuy,
    Auth,
    Landing,
    GameHistoryTable,
    BetWins,
    RelaxHomeTiles,
  },
  computed: {
    ...mapGetters(['activeRoom', 'me', 'isLoggedIn', 'landingpage', 'categoryOptions']),
    getHomeCategories() {
      return this.categoryOptions.filter((category) => {
        return category.show_in_home;
      })
    }
  },
  setup() {
    const relaxS = relaxStore();

    const trendingOptions = {
      title: "Trending Slots",
      icon: icons.Trending,
      slug: 'trending-slots',
      categories: ['slots'],
      options: {},
    };

    return {
      relaxS,
      trendingOptions,
    };
  },
  async created() {
    await this.init();
  },
  data() {
    return {
      loading: true,
      trendingGames: [],
    };
  },
  methods: {
    async init() {
      /*try {
	      this.trendingGames = await this.relaxS.fetchTrending();
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }*/
    },
  },
  watch: {
    '$route.query.aid': {
      handler: function (newState) {
        if (this.$route.query.aid) {
          window.localStorage['aid'] = this.$route.query.aid;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
